<template>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-1 lg:gap-4 items-center mx-5 lg:mx-40">
        <Description />
        <div class="flex flex-col lg:flex-row justify-center px-0 lg:px-0">
            <div class="lg:mr-2 font-mono">
                <span class="block font-semibold mb-2">Danh sách người tham gia: {{ countPeople }}</span>
                <textarea v-model="data" name="data" id="data" cols="30" rows="10"
                    class="rounded-lg w-full lg:w-auto bg-white p-5 border shadow-lg focus:outline-none focus:ring"
                    placeholder="Vui lòng điền thông tin người tham gia, cách nhau mỗi dòng"></textarea>
            </div>
            <div class="mt-5 lg:mt-0 lg:ml-5">
                <div class="mb-5 font-mono">
                    <label for="team" class="block font-semibold mb-2">Số lượng đội:</label>
                    <input v-model="team" type="number" name="team" id="team"
                     class="w-full lg:w-auto p-3 shadow-lg rounded-lg bg-white border focus:outline-none focus:ring">
                </div>
                <div class="mb-5 font-mono">
                    <label for="team" class="block font-semibold mb-2">Số lượng thành viên mỗi đội:</label>
                    <input v-model="member" type="number" name="member" id="member"
                     class="w-full lg:w-auto p-3 shadow-lg rounded-lg bg-white border focus:outline-none focus:ring">
                </div>
                <div class="grid grid-cols-2 gap-4 font-mono">
                    <button @click="createTeam()"
                        class="transition-all duration-300 ease-in-out animate-pulse p-3 w-full lg:w-auto shadow-lg rounded-lg bg-blue-500 text-white font-semibold outline-none focus:outline-none focus:ring hover:shadow-none hover:bg-blue-800"
                    >Xác nhận </button>
                    <button @click="() => {teams = null; data = null; team = 0;}"
                        class="transition-all duration-300 ease-in-out p-3 w-full lg:w-auto shadow-lg rounded-lg bg-red-500 text-white font-semibold outline-none focus:outline-none focus:ring hover:shadow-none hover:bg-red-800"
                    >Reset</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="teams" class="mt-10 px-2 lg:px-10">
        <div class="text-center font-semibold text-3xl uppercase mb-5">KẾT QUẢ</div>
        <div class="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-4 justify-center mb-10">
            <template v-for="(item, index) in teams" :key="index">
                <Card :data="item" :index="index" :color="randomColor()" />
            </template>
        </div>
        <div class="text-center mb-5">
            <button @click="sendMail()"
                class="transition-all duration-300 ease-in-out p-3 w-full lg:w-auto shadow-lg rounded-lg bg-blue-500 text-white font-semibold outline-none focus:outline-none focus:ring hover:shadow-none hover:bg-blue-800"
            >Gửi mail</button>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import Description from '@/components/Description.vue'
export default {
    components: {
        Card, Description
    },

    data() {
        return {
            data: null,
            team: 0,
            member: 0,
            teams: null
        }
    },

    computed: {
        countPeople: function () {
            if(this.data == null) {
                return 0
            }
            let array = this.data.split('\n')
            return array.length
        }
    },

    watch: {
        team: function() {
            if (this.team > this.countPeople)
                this.team = this.countPeople

            // this.member = Math.floor(this.countPeople / this.team)
        },
        member: function () {
            this.team = Math.ceil(this.countPeople / this.member)
        }
    },

    methods: {
        randomColor() {
            const r = () => Math.floor(256 * Math.random());

            return `rgb(${r()}, ${r()}, ${r()})`;
        },
        createTeam() {
            const array = this.data.split('\n');
            const randomOrder = array.sort(() => 0.5 - Math.random())

            const teams = this.chunkArray(randomOrder, Math.abs(this.team))
            this.teams = teams
            console.log(teams);
        },

        chunkArray (arr, chunkCount) {
            const chunks = [];
            while (arr.length) {
                const chunkSize = Math.ceil(arr.length / chunkCount--);
                const chunk = arr.slice(0, chunkSize);
                chunks.push(chunk);
                arr = arr.slice(chunkSize);
            }
            return chunks;
        },
        handleTeams() {
            if (this.countPeople < team)
                team = this.countPeople
        },
        sendMail() {
            let receiver = prompt("Điền thông tin người nhận");
            if (!receiver) return;
            fetch("https://testrandom.hmcdat.dev/mail.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    teams: this.teams,
                    receiver
                })
            }).then(data => data.text()).then(data => alert(data));
        }
    }

}
</script>

<style>

</style>