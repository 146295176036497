<template>
    <div class="my-20 lg:my-0">     
        <div class="flex">
            <h2 class="animate-bounce text-xl lg:text-2xl text-gray-700 font-mono font-semibold">🌀</h2>
            <h2 class="text-xl lg:text-2xl text-gray-700 font-mono font-semibold"><span class="font-bold text-blue-500">Random</span> Team Picker</h2>              
        </div>
        <p class="leading-loose font-mono text-xl md:text-2xl">
            Hệ thống tạo nhóm ngẫu nhiên từ danh sách cho trước
        </p>
    </div>
</template>

<script>
export default {

}
</script>